import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    ImmediateOutputParameters,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/pgsql";
import {Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {CodeBlend} from "../../components/code";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires libpq version 7.1.x or higher.
    </Callout>
);

const sDBString = () => (
    <>
        <p>Connection string in the following format:</p>
        <CodeSnippet>
            {`[<server_name>@][<database_name>][;<options>]`}
        </CodeSnippet>
        <ul>
            <li>
                <code>""</code>{' '} or {' '}<code>"@"</code>{' '} - empty string or '@' character, connects to a local
                server
            </li>
            <li>
                <code>{`<database_name>`}</code>{' '} or {' '}<code>{`@<database_name>`}</code>{' '} - connects to a
                database with the specified name on local server
            </li>
            <li>
                <code>{`<serve_name>@`}</code>{' '} - connects to the specified server
            </li>
            <li>
                <code>{`<server_name>@<database_name>`}</code>{' '} - connects to a database with the specified name
                on the specified server
            </li>
        </ul>
        <p>
            <code>{`<server_name>`}</code>{' '} can have the following formats:
        </p>
        <ul>
            <li>hostname[,port]</li>
            <li>pathname of the Unix socket that is used to connect to the server</li>
        </ul>
        <p>
            <code>{`<options>`}</code>{' '} string used for {' '}<CodeBlend>PQsetdbLogin</CodeBlend>{' '} function
            {' '}<CodeBlend>pgoptions</CodeBlend>{' '} parameter (these are the server process parameters).
        </p>
        <p>
            <code>{`<database_name>`}</code>{' '} string defines the database name for the connection but it can also
            define the client options like: {' '}<CodeBlend>"dbname=mydb connect_timeout=10"</CodeBlend>.
        </p>
    </>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const PostgreSQLLobNotes = () => (
    <>
        <p>
            Working with PostgreSQL Large Objects data
            (Oid field type) includes some special considerations.
        </p>
        <p>
            Generally, when you fetch Oid field
            data (which can point to any object, not necessary PostgreSQL Large
            Object) SQLAPI++ returns its value as a number (SQLAPI++ returns an
            object identifier).
        </p>
        <p>
            If you want to retrieve Oid field as PostgreSQL
            Large Object you should set command-related option
            {' '}<code>OidTypeInterpretation</code>{' '}
            to {' '}<code>"LargeObject"</code>{' '} value before the command execution,
            by calling {' '}<ApiLink>SACommand::setOption</ApiLink>.
        </p>
    </>
);

const Page = () => (
    <ServerGuide server="PostgreSQL"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 lobNotes={PostgreSQLLobNotes}
                 outputParamNotes={() => <ImmediateOutputParameters server="PostgreSQL"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="PostgreSQL"
                                                                      nativeCommand="PQrequestCancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="PostgreSQL"
                                                                        cppHeader="pgAPI.h"
                                                                        apiClass="pgAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="PostgreSQL"
                                                                                handleClass="pgConnectionHandles"
                                                                                cppHeader="pgAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="PostgreSQL"
                                                                          handleClass="pgCommandHandles"
                                                                          cppHeader="pgAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="PostgreSQL"/>}
    />
);

export default Page;
