import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'LIBPQ.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified LIBPQ library.`,
        valid:
            <>
                Any valid LIBPQ library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"libpq.dll;pq.dll</code></li>
                <li>Linux - {' '}<code>"libpq.so:libpq.so.5:libpq.so.4:libpq.so.3"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked PostgreSQL client API functions when
                the library is compiled with {' '}<CodeBlend>SA_STATIC_PQSQL</CodeBlend>{' '} build option.
            </>
    },
    {
        name: 'APPNAME',
        scope: 'connection',
        description: `Specifies the client application name (PostgreSQL 9.0 and 
                      higher 'application_name' configuration parameter).`,
        valid:
            <>
                client application name string
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'ClientEncoding',
        scope: 'connection',
        description:
            <>
                Uses {' '}<CodeBlend>PQsetClientEncoding</CodeBlend>{' '} to set up PostgreSQL client encoding
                (for non-Unicode SQLAPI++ version only).
            </>,
        valid:
            <>
                See PostgreSQL documentation
            </>
    },
    {
        name: 'OidTypeInterpretation',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ Library to interpret fields of Oid type as an object identifier or a Large Object.
                After you set {' '}<code>"LargeObject"</code>{' '} value to this option, SQLAPI++ interprets Oid type
                fields data as BLob data. Otherwise, it reads them as object identifiers (as ordinary numbers).
            </>,
        valid:
            <>
                <code>"LargeObject"</code>{' '} and {' '}<code>"Oid"</code>
            </>,
        defaultValue:
            <code>"Oid"</code>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use scrollable dynamic server side cursor.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'UseCursor',
        scope: 'command',
        description: `Forces SQLAPI++ to use server side cursor within "select .." statement.`,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'SetCursorName',
        scope: 'command',
        description: `Allows to define the cursor name SQLAPI++ uses for the server side cursor 
                      within "select .." statement.`,
        valid:
            <>
                See PostgreSQL documentation
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SNAPSHOT_ID',
        scope: 'command',
        description: `Allows to define the transaction snapshot that SQLAPI++ uses within the next transaction block.`,
        valid:
            <>
                See PostgreSQL documentation
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'UsePrepared',
        scope: 'command',
        description: `Forces SQLAPI++ to use prepared statement with the current SACommand.`,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    }
];

export {
    options
};
